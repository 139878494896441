import * as React from "react";

import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import Link from '../components/Link';
import List from '../components/List';
import Seo from '../components/Seo';

import FacebookIcon from '../components/icons/FacebookIcon';
import TelegramIcon from '../components/icons/TelegramIcon';
import TwitterIcon from '../components/icons/TwitterIcon';

const SupportPage = ({ pageContext }) => {

  return (
    <>
      <Seo
        title="Підтримати проєкт | Перлини української народної творчості"
        descriptions="Підтримайте розвиток сайту"
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
            crumbLabel="Підтримати проєкт"
          />
          <h1 className="typo-h1 max-w-lg lowercase mt-4">
            Підтримати проєкт
          </h1>
          <p className="typo-body mt-4">
            Дізнайтесь як підтримати проєкт "Перлини української народної творчості"
          </p>
        </div>
      </div>
      <div className="container-lg my-12 md:my-24">
        <h2 className="typo-h2 mb-4">
          Нам потрібна Ваша підтримка
        </h2>
        <p className="typo-body">
          "Перлини української народної творчості" – це проєкт ентузіастів, які працюють над збиранням та збереженням
          творів різних категорій українського фольклору в електронному вигляді. Однак це складно реалізовувати без
          додаткової підтримки. Тому, якщо Ви відчуваєте, що українська культура та культурна спадщина Вам близька та
          цікава, можете підтримати цей проєкт такими способами:
        </p>
        <h2 className="typo-h2 mb-4 mt-12">
          Інформаційна підтримка
        </h2>
        <p className="typo-body mb-4">
          На сторінках сайту Ви можете знайти кнопки для поширення матеріалу через соціальні мережі. Діліться цікавим
          матеріалом через свої профілі в соціальних мережах, розповідайте близьким, друзям та знайомим про сайт, та
          долучайтесь до наших спільнот:
        </p>
        <div className="flex items-center">
          <Button to="https://www.facebook.com/folklore.com.ua" variant="primary" size="xs" className="mr-4">
            Facebook
            <FacebookIcon className="inline-block h-6 w-6 ml-4" />
          </Button>
          <Button to="https://t.me/folklorecomua" variant="primary" size="xs" className="mr-4">
            Telegram
            <TelegramIcon className="inline-block h-6 w-6 ml-4" />
          </Button>
          <Button to="https://twitter.com/folklorecomua" variant="primary" size="xs">
            Twitter
            <TwitterIcon className="inline-block h-6 w-6 ml-4" />
          </Button>
        </div>
        <h2 className="typo-h2 mb-4 mt-12">
          Фінансова підтримка
        </h2>
        <p className="typo-body mb-4">
          Оскільки наша ініціатива не є комерційною, нам дуже важлива фінансова підтримка. Залучення додаткових коштів
          дозволяє нам вкладати більше сил та часу в розвиток та підтримку проєкту. Проєкт "Перлини української
          народної творчості" працює завдяки постійній підтримці нашої спільноти. Ми дуже вдячні кожному патрону
          за кожний донат.
        </p>
        <List className="mb-4">
          <List.Item className="typo-body">
            Ставайте патроном на
            {' '}
            <Link to="https://www.patreon.com/folklorecomua" className="text-red-500">
              Patreon
            </Link>
          </List.Item>
          <List.Item className="typo-body">
            Підтримайте переказом на рахунок monobank:
            {' '}
            <span className="text-red-500">
              4441&nbsp;1111&nbsp;5856&nbsp;7507
            </span>
          </List.Item>
        </List>
      </div>
    </>
  );
};

export default SupportPage;
